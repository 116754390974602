import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from '../components/subscribe'


const Container = styled.div`
  h1 {
    margin: auto 0;
    padding-top: 0.5em;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coming {
    margin-top: 50px;
    text-align: center;
    padding-top: 200px;
    padding-bottom: 400px;
  }
`

const BreakoutPage = () => {

  return (
    <Layout>
      <SEO title="Event Schedule" />
  
      <Container>
        <div className="header">
          <h1>Event Schedule</h1>
        </div>
        <div className="coming">
          Coming soon...
        </div>
        <Subscribe />
  
      </Container>
    </Layout>
  )
}

export default BreakoutPage
